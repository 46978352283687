<template>
  <v-app>

    <login-content :loading="loading">

      <v-form
        ref="form"
        class="pa-4"
        @submit.prevent="submit"
      >

        <textfield
          v-model="login.email"
          class="mt-6 mb-4"
          color="primary"
          label="Usuari"
          :rules="rules.email"
          autocomplete="user"
          outlined
        />

        <textfield
          v-model="login.password"
          type="password"
          class="mb-4"
          color="primary"
          label="Contrasenya"
          :rules="rules.password"
          autocomplete="password"
          outlined
        />

        <btn type="submit" :loading="loading" class="mb-6" color="primary">
          Iniciar sessió
        </btn>

        <v-divider class="mb-4"/>

        <p class="caption mb-2">
          <router-link class="grey--text" to="/recover-password">Has oblidat la contrasenya?</router-link>
        </p>

        <p class="caption mb-2">
          <v-icon class="mr-1" small>mdi-arrow-left</v-icon>
          <router-link class="grey--text" to="/">Tornar enrere</router-link>
        </p>

      </v-form>
    </login-content>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { required } from '@/utils';
import Btn from '@/components/Btn';
import Textfield from '@/components/inputs/Textfield';
import LoginContent from '@/components/LoginContent';
import Alert from '@/components/Alert';

export default {
  name: 'Login',
  components: { LoginContent, Alert, Textfield, Btn },
  data: () => ({
    login: {
      email: '',
      password: ''
    },
    rules: {
      email: [ required('Introdueix el teu correu electrònic.') ],
      password: [ required('Introdueix la contrasenya.') ]
    }
  }),
  computed: mapState( 'auth', [ 'userID', 'loading' ]),
  methods: {
    ...mapMutations( 'app', [ 'setAlert' ]),
    submit() {
      if ( this.$refs.form && this.$refs.form.validate()) {
        this.$store.dispatch( 'auth/login', this.login ).catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
        });
      }
    }
  },
  watch: {
    userID( value ) {
      value && this.$router.push('/');
    }
  },
  mounted() {
    this.$refs.form && this.$refs.form.reset();
  }
}
</script>
