<template>
  <div class="login-content">

    <div
      v-if="loading"
      class="app-block"
    />

    <v-row class="login-body grey lighten-5" align="center" justify="center" no-gutters>

      <v-card width="100%" max-width="480" outlined>

        <v-sheet class="pa-4" color="black">
          <img
            src="@/assets/NG_logo_generalitat.png"
            srcset="@/assets/NG_logo_generalitat.svg"
            width="170"
            height="27"
          />
        </v-sheet>

        <slot/>

      </v-card>
    </v-row>

    <alert
      v-model="alert.show"
      :color="alert.type"
      :message="alert.message"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import Alert from '@/components/Alert';

export default {
  components: { Alert },
  computed: mapState( 'app', [ 'alert' ]),
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.login-content {
  height: 100%;
}
.login-body {
  height: 100%;
  padding: 8px;
}
</style>
