<template>
  <v-app>

    <login-content :loading="loading">

      <div v-if="success" class="pa-4">

        <p>La contranseya ha sigut actualitzada correctament</p>

        <btn color="primary" to="/login">
          Iniciar sessió
        </btn>

      </div>

      <v-form
        v-else
        ref="form"
        class="pa-4"
        @submit.prevent="submit"
      >

        <textfield
          v-model="data.email"
          class="mt-6 mb-4"
          color="primary"
          label="Usuari"
          :rules="rules.email"
          autocomplete="email"
          outlined
        />

        <div v-show="codeSent">

          <v-divider class="mb-4" />

          <p class="mb-4">
            Li hem enviat un codi al seu correu electrònic per tal de poder
            resetejar la contrasenya del seu usuari. Si us plau, revisi el seu
            correu electrònic.
          </p>

          <textfield
            v-model="data.code"
            class="mt-6 mb-4"
            color="primary"
            label="Codi del correu electrònic"
            :rules="rules.code"
            outlined
          />

          <textfield
            v-model="data.password"
            type="password"
            class="mb-4"
            color="primary"
            label="Contrasenya"
            autocomplete="new-password"
            :rules="rules.password"
            outlined
          />

          <textfield
            v-model="validatePassword"
            type="password"
            class="mb-4"
            color="primary"
            label="Repetir contrasenya"
            autocomplete="confirm-password"
            :rules="rules.validatePassword"
            outlined
          />

        </div>

        <btn type="submit" :loading="loading" class="mb-6" color="primary">
          {{ codeSent ? 'Canviar contrasenya' : 'Recuperar contranseya' }}
        </btn>

        <v-divider class="mb-4" />

        <p class="caption mb-2">
          <v-icon class="mr-1" small>mdi-arrow-left</v-icon>
          <router-link class="grey--text" to="/login">Tornar enrere</router-link>
        </p>

      </v-form>

    </login-content>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { required } from '@/utils';
import Btn from "@/components/Btn";
import Textfield from "@/components/inputs/Textfield";
import LoginContent from '@/components/LoginContent';
import Alert from '@/components/Alert';

export default {
  name: "RecoverPassword",
  components: { LoginContent, Alert, Textfield, Btn },
  data: () => ({
    data: {
      email: "",
      code: "",
      password: "",
    },
    codeSent: false,
    success: false,
    validatePassword: ""
  }),
  computed: {
    ...mapState( "auth", [ "userID", "loading" ]),
    rules() {
      return {
        email: [
          required("Introdueix el teu correu electrònic.")
        ],
        code: this.codeSent ? [
          required("Introdueix el codi rebut en el correu electrònic.")
        ] : [],
        password: this.codeSent ? [
          required("Introdueix una nova contranseya.")
        ] : [],
        validatePassword: this.codeSent ? [
          required("Repeteix la contrasenya."),
          v => v === this.data.password || 'La contranseya no coincideix.'
        ] : []
      }
    }
  },
  methods: {
    ...mapMutations( 'app', [ 'setAlert' ]),
    submit() {
      if ( this.$refs.form && this.$refs.form.validate()) {
        if ( ! this.codeSent ) this.submitRecover();
        else this.submitReset();
      }
    },
    submitRecover() {
      this.$store
        .dispatch( "auth/recoverPassword", { email: this.data.email })
        .then( result => {
          this.$refs.form && this.$refs.form.resetValidation();
          this.codeSent = result == 0;
        })
        .catch( err => {
          console.error( err.message );
          this.setAlert({ type: 'error', message: err.message });
        });
    },
    submitReset(e) {
      this.$store
        .dispatch( "auth/resetPassword", this.data )
        .then( result => {
          if ( result == 0 ) {
            this.success = true;
          }
        })
        .catch( err => {
          console.error(err.message);
          this.setAlert({ type: 'error', message: err.message });
        });
    }
  },
  watch: {
    userID(value) {
      value && this.$router.push("/");
    }
  },
  mounted() {
    this.success = this.codeSent = false;
    this.$refs.form && this.$refs.form.reset();
  },
};
</script>

<style>
.login {
  height: 100%;
  padding: 8px;
}
</style>
